import { ReactComponent as iDesign } from "./ic_design.svg";
import { ReactComponent as iBrand } from "./ic_make_brand.svg";
import { ReactComponent as iMission } from "./ic_mission.svg";
import { ReactComponent as iAccounting } from "./features/bank.svg";
import { ReactComponent as iBell } from "./features/envelope-dot.svg";
import { ReactComponent as iCoins } from "./features/coins.svg";
import { ReactComponent as iCard } from "./features/credit-card.svg";
import { ReactComponent as iHelp } from "./features/interrogation.svg";
import { ReactComponent as iSmart } from "./features/mobile-notch.svg";
import { ReactComponent as iSave } from "./features/sack-dollar.svg";
import { ReactComponent as iStats } from "./features/stats.svg";
import { ReactComponent as iUsers } from "./features/users.svg";
import { ReactComponent as iLogoDark } from "./logo/logo-dark.svg";
import { ReactComponent as iLogoLight } from "./logo/logo-light.svg";
import { ReactComponent as iEzyfiLogo } from "./logo/ezyfi-logo.svg";
import { ReactComponent as iAccountant } from "./illustrations/accountant.svg";
import { ReactComponent as iManage } from "./illustrations/manage1.svg";
import { ReactComponent as ipolicy } from "./privacypolicy.svg";

export const DesignSVG = iDesign;
export const BrandSVG = iBrand;
export const MissionSVG = iMission;
export const AccountingSVG = iAccounting;

export const BellSVG = iBell;
export const CoinsSVG = iCoins;
export const CardSVG = iCard;
export const HelpSVG = iHelp;
export const SmartSVG = iSmart;
export const SaveSVG = iSave;
export const StatsSVG = iStats;
export const UsersSVG = iUsers;
export const PolicySVG = ipolicy;

// Logo
export const LogoDark = iLogoDark;
export const LogoLight = iLogoLight;
export const EzyfiLogo = iEzyfiLogo;

// Illustrations
export const AccountantSVG = iAccountant;
export const ManageSVG = iManage;
