import iIsaac from "./isaacwere.jpg";
import iDoreen from "./Doreen-Kansiime.jpg";
import iBrian from "./Brian-Ssemate.jpg";
import iDorine from "./Dorine-Sanyu.jpg";
import iElly from "./Elly-Yiga.jpg";
import iFrancis from "./Francis-Kyagulanyi.jpg";
import iJerry from "./Jerry-Vance-Anguzu.jpg";
import iJuliet from "./julieimg1.jpg";
import iMichael from "./Micheal-Kibande.jpg";
import iOshin from "./oshin.jpg";
import iSasha from "./Sasha-Sauda.jpg";
import iShamilah from "./shamirah.jpg";
import iSam from "./Sam-Ellis.jpg";
import iPatrick from "./Patrick-Lwanga.jpg";
import iAnna from "./annaimg1.jpg";
import iStella from "./stellaimg1.jpg";
import iJoy from "./joyimg1.jpg";
import iMariam from "./mariamimg1.jpg";
import iGladys from "./GladysImg.jpg";
import iZahara from "./zahara.jpg";
import iMoses from "./mosesimg1.jpg"

export const IsaacImg = iIsaac;
export const DoreenImg = iDoreen;
export const BrianImg = iBrian;
export const DorineImg = iDorine;
export const EllyImg = iElly;
export const FrancisImg = iFrancis;
export const JerryImg = iJerry;
export const JulietImg = iJuliet;
export const MichaelImg = iMichael;
export const OshinImg = iOshin;
export const SashaImg = iSasha;
export const SamImg = iSam;
export const ShamilahImg = iShamilah;
export const GladysImg = iGladys;
export const JoyImg = iJoy;
export const MariamImg = iMariam;
export const StellaImg = iStella;
export const AnnaImg = iAnna;
export const PatrickImg = iPatrick;
export const ZaharaImg = iZahara;
export const MosesImg = iMoses;
