import React from 'react'
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { contactLink } from "../constants/links";
import { Posimg } from '../assets/img/Images';



export const Pos = () => {
  return (
    <div className='poka-pattern'>
       <Wrapper className="container flexSpaceCenter">
       <LeftSide className="flex flexColumn">
          <GapWrapper gap={10}>
            <div
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1500"
            >
              <h1 className="extraBold font60">
                <HighlightSpan>Point of Sale</HighlightSpan>
              </h1>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="600"
            >
              <div className="font18 extraBold">
                P.O.S Machines
              </div>
            </div>
          </GapWrapper>

          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
            <TitleP className="font14">
            At <b>Zimba</b>, we understand the challenges our customers face when dealing with outdated,
             overpriced <b>Point of Sale (POS) systems</b>. 
             That’s why we’ve partnered with a leading <b>POS</b> manufacturer to offer high-quality, 
             modern devices at an affordable price of <b>1.3M UGX</b>.
              Our <b>POS</b> devices are not just another addition to your toolkit; 
              they are seamlessly integrated with Zimba’s core banking platform, 
              making them a powerful tool for your loan and collection officers in the field.
              <br />
              <br />
              <b>POS</b> can handle various payment methods, 
              including credit and debit cards, mobile payments, and cash.
            </TitleP>
          </div>

          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
          >
            <HeaderButton to={contactLink}><p className='gilroy'>More Info</p></HeaderButton>
          </div>
        </LeftSide>
        <RightSide>
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
            className=''
          >
            <img width={400} src={Posimg} alt="image-alt-text" />
          </div>
        </RightSide>
       </Wrapper>
    </div>
  )
}
const Wrapper = styled.section`
  padding-top: 70px;
  width: 100%;
  min-height: 90vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const RightSide = styled.div`
  width: 50%;
  padding: 30px;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
  }
`;

