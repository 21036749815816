import React from 'react'
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import Footer from "./Footer";
import { Privcy } from './privcy';
import { PolicySVG } from '../assets/svg/Svgs';

// export const PrivacyPolicy = () => {
//   const handleScrollToBoxes = () => {
//     document.getElementById("boxes-section").scrollIntoView({ behavior: "smooth" });
//   };

export const PrivacyPolicy = () => {
  // const handleScrollToSection = (sectionId) => {
  //   document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  // };

  // const handleScrollToTop = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  

  return (
    <div>
    <div className='poka-pattern'>
       <Wrapper className="container flexSpaceCenter">
        <LeftSide className="flex flexColumn">
          <GapWrapper gap={10}>
            <div
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1500"
            >
              <h1 className="extraBold font60">
              Privacy <HighlightSpan>Policy</HighlightSpan>
              </h1>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="600"
            >
              <div className="font18 extraBold">
        
              </div>
            </div>
          </GapWrapper>

          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
            <TitleP className="font14">
            This Privacy Policy outlines how we collect, use, disclose, 
            and protect your information when you visit our website or use our services.
              <br />
              <br />
            </TitleP>
            <TitleP className="font14">
            Last Updated on 28/08/24
              <br />
              <br />
            </TitleP>
          </div>

          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
          >
            {/* <HeaderButton onClick={handleScrollToBoxes}>Read More</HeaderButton> */}
          </div>

        </LeftSide>
        
        <RightSide>
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
            className='mx-96'
          >
            {/* <img width={400} src={Ills1} alt="image-alt-text" /> */}
            <div>
            <PolicySVG width={500} />
            </div>
          </div>
        </RightSide>
      </Wrapper>
      
    </div>
    <Privcy />
    <Footer />
    </div>
    
  )
}



const Wrapper = styled.section`
  padding-top: 70px;
  width: 100%;
  min-height: 90vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const RightSide = styled.div`
  width: 50%;
  padding: 30px;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
  }
`;

