import {
  AccessibilityNew,
  CheckCircle,
  CreditScore,
  Devices,
  Handyman,
  InvertColors,
  LocalPhone,
  LocationOn,
  LockPerson,
  Mail,
} from "@mui/icons-material";
import {
  BrianImg,
  DoreenImg,
  MariamImg,
  EllyImg,
  StellaImg,
  IsaacImg,
  JulietImg,
  JoyImg,
  OshinImg,
  SamImg,
  AnnaImg,
  ShamilahImg,
  MosesImg,
  
} from "../assets/img/team/TeamImages";
import {
  AccountingSVG,
  BellSVG,
  BrandSVG,
  CardSVG,
  CoinsSVG,
  DesignSVG,
  HelpSVG,
  MissionSVG,
  SaveSVG,
  SmartSVG,
  StatsSVG,
  UsersSVG,
} from "../assets/svg/Svgs";
import { Tag } from "antd";
import { HighlightSpan, RowWrapper } from "../components/styled/Styled";
import { Ills1,
  Wazeshaimg,
  Khezzaimg,
  Amaniimg,
  Duckhillimg,
  Sleekersimg,


 } from "../assets/img/Images";

// WHY OPTIONS
export const whyData = [
  {
    Icon: InvertColors,
    title: "Timeless Aesthetics",
    desc:
      "Experience the perfect blend of beauty and simplicity with our intuitive interface. Effortlessly manage everything a single, unified platform, ensuring seamless navigation and ease of use.",
  },

  {
    Icon: Handyman,
    title: "Intuitive Customization",
    desc:
      "Our systems offer an ideal balance of flexibility and power. With our robust admin settings, you are in complete control and can tailor the system to your preferences and needs.",
  },
  {
    Icon: LockPerson,
    title: "Reliable and Secure",
    desc:
      "Rest assured that all your data and information are securely stored on AWS, renowned as one of the world's most trusted and secure cloud server.",
  },

  {
    Icon: AccessibilityNew,
    title: "Dedicated to Accessibility",
    desc:
      "We prioritize your needs with utmost seriousness. Feel free to visit us or reach out to us via phone or email at any time during our business hours. We are always here to assist you.",
  },
];

// FAQS
export const faqData = [
  {
    label: "How secure is our data on your platform?",
    children:
      "Your data is stored on AWS, one of the most secure cloud servers in the world. AWS offers security features such as Identity and access management (IAM), Single Sign On (SSO), SSL and TLS provisioning , and Web application firewall (WAF) to name a few. Contact us if you want to know more about how we protect your data.",
  },
  {
    label: "Does Zimba’s platform require internet to operate?",
    children:
      "Yes, Zimba is cloud based and therefore requires internet, but we have built the platform to run on minimal bandwidth.",
  },

  {
    label:
      "Will Zimba train us on how to use the platform and how will we transfer our data from our old system?",
    children:
      "Yes, we have an onboarding team that will train you and show you how to transfer you old data.",
  },
  {
    label: "How much will it cost us to use Zimba?",
    children: (
      <>
        Our pricing depends on the size of your institution; but we are one of
        the most affordable on the market. We charge one consistent price every
        month and do not require a large upfront payment like competitors.
      </>
    ),
  },

  {
    label: (
      <p>
        Can Zimba be accessed on phones or tablets?{" "}
        <Tag color="green">Beta</Tag>
      </p>
    ),
    children: (
      <>
        We do not yet have a downloadable application, but yes, you can access
        Zimba on your smart phone or tablet as a web application.
      </>
    ),
  },

  {
    label:
      "How do we contact you if we are having issues with the Zimba platform?",
    children: (
      <>
        You can email, call, message, or visit us at anytime during business
        hours. We will do everything in our power to resolve your issue in a
        timely manner.
      </>
    ),
  },
];

// PLANS
export const plansData = [
  {
    title: "Free",
    tag: "Starter",
    desc: "Not sure if you will like us? We offer a 2 week free trial",
    button: "Get Started",
    features: [
      "Test out different elements of our platform to see if it’s a good fit",
      "Free features",
    ],
  },
  {
    title: "Silver",
    desc: "For medium budget projects",
    button: "Begin Transformation",
    tag: "Recommended",
    features: [
      "Loans",
      "Savings",
      "Clients",
      "Accounting",
      "Reports",
      "Shares",
    ],
  },
  {
    title: "Gold",
    desc: "For high budget companies and enterprises",
    button: "Go to the Next Level",
    tag: "PRO",
    features: [
      "Loans",
      "Savings",
      "Clients",
      "Accounting",
      "Reports",
      "Shares",
      "SMS Banking",
      "Mobile Application",
      "Mobile Money Integration",
    ],
  },
];

// TEAM MEMBERS
const teamModel = (name, title, image) => {
  return { name: name, title: title, image: image };
};

export const teamMembers = [
  teamModel("Isaac Were", "Co-Founder and CEO", IsaacImg),
  teamModel("Sam Ellis", "CTO", SamImg),
  teamModel("Oshin Were", "Head of Business Development", OshinImg),
  teamModel("Shamilah Hatibu", "Operations Manager", ShamilahImg),
  teamModel("Brian Ssemate", "Lead Engineer", BrianImg),
  teamModel("Elly Yiga", "Senior Software Engineer", EllyImg),
  //teamModel("Nansamba Zahara", "Software Engineer", ZaharaImg),
  //teamModel("Nganda Gladys", "Software Engineer", GladysImg),
  teamModel("Juliet Laker", "Administrator", JulietImg),
  //teamModel("Jerry Vance Anguzu", "Software Engineer", JerryImg),
  teamModel("Doreen Kansiime", "Team Lead Sales & Onboarding", DoreenImg),
  teamModel("Anna Tana Mukiibi", "IT specialist", AnnaImg),
  teamModel("Mariam Nalutaaya", "Sales & Onboarding", MariamImg),
  teamModel("Joy Aluo", "Sales & Onboarding", JoyImg),
  teamModel("Stella Akol", "Sales & Onboarding", StellaImg),
  teamModel("Moses Nandala", "Sales & Onboarding", MosesImg),
];

// objectives
export const objectivesData = [
  {
    title: "Vision",
    desc: "To build a brighter and more inclusive tomorrow.",
    Icon: BrandSVG,
  },
  {
    title: "Mission",
    desc:
      "To bridge financial gaps in developing markets through innovation and technology.",
    Icon: MissionSVG,
  },
  {
    title: "Objectives",
    desc: (
      <>
        To cultivate economic growth and inclusion through financial
        infrastructure. <br />
        For every financial institution to have access to a one stop shop to
        success.
      </>
    ),
    Icon: DesignSVG,
  },
];

// features
export const featuresData = [
  {
    title: "Loans",
    desc:
      "Efficiently manage loan applications, seamlessly approve loans, promptly disburse funds, effortlessly track payments, and conveniently monitor collateral and guarantors, all within a single integrated platform.",
    Icon: CoinsSVG,
  },
  {
    title: "Savings",
    desc:
      "Effortlessly oversee all savers and their savings, streamline deposits and withdrawals, and efficiently handle any associated fees, ensuring a seamless management experience.",
    Icon: SaveSVG,
  },
  {
    title: "Clients",
    desc:
      "Effortlessly maintain comprehensive records of your clients' information, ranging from personal and business details to next of kin contacts. Seamlessly upload relevant files, such as passport photos and signatures, specific to each client for convenient access and organization.",
    Icon: UsersSVG,
  },
  {
    title: "Client Mobile App",
    desc:
      "Empower your members with our mobile app, enabling them to conveniently apply for loans, effortlessly access statements, conveniently make loan payments, and seamlessly manage deposits and withdrawals",
    Icon: SmartSVG,
  },

  {
    title: "SMS Notifications",
    desc:
      "Enhance member communication by leveraging our automated SMS messaging system, ensuring timely notifications are sent whenever transactions or other pertinent actions occur.",
    Icon: BellSVG,
  },
  {
    title: "Mobile Money",
    desc:
      "Embrace the convenience of a cashless society and seamlessly transact using mobile money. Effortlessly disburse loans and empower your members to make payments, deposits, and withdrawals, all through MTN’s and Airtel’s secure and efficient mobile money wallets.",
    Icon: CardSVG,
  },
  {
    title: "Customer Service/Support",
    desc:
      "We take immense pride in our product and value our clients, which is why we have assembled a dedicated team committed to delivering an exceptional experience for you.",
    Icon: HelpSVG,
  },
  {
    title: "Accounting",
    desc:
      "Effortlessly manage a wide range of financial as, including your chart of accounts, payables, receivables, staff till sheets, expenses, incomes, and much more.",
    Icon: AccountingSVG,
  },
  {
    title: "Reports",
    desc:
      "Gain access to a comprehensive suite of reports, ranging from income statements and balance sheets to arrears reports, aging reports, and much more.",
    Icon: StatsSVG,
  },
];

// testimonials
export const testimonialData = [
  {
    text:
      `“We are able to see all client information like principal and interest amount, total payment made in the system total balance and amount in arrears this makes us make decisions faster”
`,
    name: "Sharon",
    occupation: "Manager Amani SACCO",
    image: Amaniimg,
  },
  {
    text:
      `"You have very good customer service. I am given attention and all my issues are resolved. This makes me so happy"`,
    name: "Milly",
    occupation: "Manager Wazesha SACCO",
    image: Wazeshaimg,
  },
  {
    text:
      `"This system has made our  work easy, fast and more so  contributed to our client growth. We started with 70 clients, but we have  over 100 clients now!"`,
    name: "Richard",
    occupation: "Operations Sleekers SACCO",
    image: Sleekersimg,
  },
  {
    text:
      `"I am able to learn via online guided  by audios and videos sent to me. I can't  even find a word to describe your support staff!"`,
    name: "Willy Otukol",
    occupation: "Regional Manager DuckHill",
    image: Duckhillimg,
  },
  {
    text:
      `"I am able to learn via online guided  by audios and videos sent to me. I can't  even find a word to describe your support staff!"`,
    name: "Martin",
    occupation: "Director Kheza MicroFinanace",
    image: Khezzaimg,
  },
];

const clients = [
  {
    title: "Savings and Credit Cooperatives (SACCOs)",
    desc:
      "We help you manage your members along with their loans, savings, and more.",
  },
  {
    title: "Micro Finance Institutions (MFIs)",
    desc:
      "Whether you are a deposit taking MFI or not, our solutions can help you maximize your reach and operations.",
  },
  {
    title: "Other Lenders",
    desc:
      "If you are in the business of lending money, our solutions can help you as well.",
  },
];

// target clients
export const targetClientsData = {
  header: (
    <p>
      Zimba is the <b>Perfect</b> Solution
    </p>
  ),
  title: (
    <p>
      Our <HighlightSpan>Target</HighlightSpan> Clients
    </p>
  ),
  desc: (
    <div className="flexColumn gap20">
      {clients.map((client, i) => (
        <RowWrapper
          className="flex gap20"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay={i * 100 + 200}
        >
          <div>
            <CheckCircle sx={{ mt: 0.5 }} fontSize="small" color="primary" />
          </div>
          <div>
            <h2>{client.title}</h2>
            <br />
            <p>{client.desc}</p>
          </div>
        </RowWrapper>
      ))}
    </div>
  ),
  Image: <img src={Ills1} alt="clients" />,
};

export const financialData = [
  {
    title: "Financial Literacy",
    Icon: CreditScore,

    content: (
      <p>
        Financial literacy is crucial, as it equips individuals with the
        knowledge and understanding of key financial concepts. These concepts
        include budgeting, saving, investing, borrowing, and managing debt. It
        also encompasses interpreting financial statements, understanding tax
        laws, and making informed decisions regarding insurance and retirement
        planning.
        <br />
        <br />
        By possessing financial literacy skills, individuals can avoid financial
        pitfalls and achieve their goals. Budgeting and saving enable living
        within means and creating a financial safety net.
        <br />
        <br />
        Wise investing aids in wealth growth and retirement preparation.
        Effective debt management prevents cycles of debt and improves credit
        access.
      </p>
    ),
  },
  {
    title: "Digital Literacy",
    Icon: Devices,
    content: (
      <p>
        Digital literacy plays a crucial role in the realm of financial products
        in Uganda. With the rapid digitization of financial services, being
        digitally literate is essential for individuals to fully benefit from
        the convenience and accessibility offered by digital financial products.
        <br />
        <br />
        Understanding how to navigate digital platforms and securely engage with
        online transactions ensures that Ugandans can access a wide range of
        financial services, including banking, payments, loans, and investments.
        <br />
        <br />
        Digital literacy empowers individuals to make informed decisions, manage
        their finances effectively, and protect themselves from digital fraud
        and risks. By embracing digital literacy, Ugandans can confidently
        engage with financial products, expand their financial inclusion, and
        drive economic growth in the country.
      </p>
    ),
  },
];

// address Data
export const addressData = [
  {
    text: "ESERI, Plot 2893 Kayondo Road, Bbuye-Ntinda, Kampala (U)",
    icon: LocationOn,
  },
  {
    text: "Tel: +256 777 767 608",
    icon: LocalPhone,
  },
  {
    text: "info@zimba.io",
    icon: Mail,
  },
];
